exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-game-generator-js": () => import("./../../../src/pages/game-generator.js" /* webpackChunkName: "component---src-pages-game-generator-js" */),
  "component---src-pages-game-generator-step-2-js": () => import("./../../../src/pages/game-generator-step-2.js" /* webpackChunkName: "component---src-pages-game-generator-step-2-js" */),
  "component---src-pages-game-preview-js": () => import("./../../../src/pages/game-preview.js" /* webpackChunkName: "component---src-pages-game-preview-js" */),
  "component---src-pages-generate-game-js": () => import("./../../../src/pages/generate-game.js" /* webpackChunkName: "component---src-pages-generate-game-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-js": () => import("./../../../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-ozge-game-2-js": () => import("./../../../src/pages/ozge-game2.js" /* webpackChunkName: "component---src-pages-ozge-game-2-js" */),
  "component---src-pages-ozge-game-js": () => import("./../../../src/pages/ozge-game.js" /* webpackChunkName: "component---src-pages-ozge-game-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-secure-js": () => import("./../../../src/pages/secure.js" /* webpackChunkName: "component---src-pages-secure-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

